import React, { useEffect, useState } from "react";
import Header from "./Header";
import CategoryList from "./CategoryList";
import RecentPosts from "./RecentPosts";
import BlogItem from "./BlogItem";
import Pagination from "./Pagination";
import Footer from "./Footer";
import axios from "axios";
import { API_ENDPOINT } from "../Api";
import AppLoader from "./AppLoader/index";
import MetaTag from "./MetaTag";

function BlogList() {
  const [recentPosts, setRecentPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const postsPerPage = 4;

  const handleCategoryClick = (categoryId) => {
    if (categoryId == null) {
      fetchPosts();
    } else {
      fetchCategory(categoryId);
    }
    setCurrentPage(1);
  };

  const fetchCategory = async (categoryId) => {
    setLoading(true);
    await axios
      .get(`${API_ENDPOINT}/posts?_embed&&categories=${categoryId}`)
      .then((response) => {
        setRecentPosts(response.data);
      })
      .catch((error) =>
        console.error("Error fetching posts for the category:", error)
      );
    setLoading(false);
  };

  const fetchPosts = async () => {
    setLoading(true);

    await axios
      .get(`${API_ENDPOINT}/posts?_embed`)
      .then((response) => {
        setRecentPosts(response.data);
      })
      .catch((error) => console.error("Error fetching recent posts:", error));
    setLoading(false);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = recentPosts.slice(indexOfFirstPost, indexOfLastPost);

  const MetaTitle = "Blog | Hilton Software Technologies";
  const MetaDescription =
    "Explore the Hilton Technologies blog for insights on digital solutions, industry trends, technology updates, and expert tips. Stay informed and inspired with our expert articles designed to help you navigate the evolving digital landscape.";
  const MetaKeywords =
    "Hilton Technologies Blog, Digital Solutions, Technology Trends, AI and ML Insights, Web Development Tips, E-commerce Strategies, Software Development, Industry News, Expert Articles, Digital Marketing";
  const path = window.location.pathname;
  return (
    <div>
      <MetaTag
        title={MetaTitle}
        description={MetaDescription}
        keywords={MetaKeywords}
        path={path}
      />
      <Header />
      <div className="rs-breadcrumbs blog">
        <div className="container-fluid">
          <div className="breadcrumbs-inner">
            <h1 className="page-title">Blog</h1>
          </div>
        </div>
      </div>
      <div className="rs-inner-blog rs-blog style2 pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">
              <div className="widget-area">
                <CategoryList onCategoryClick={handleCategoryClick} />
                <RecentPosts recentPosts={recentPosts} />
              </div>
            </div>
            <div className="col-lg-9 pr-35 md-pr-15">
              {loading ? (
                <div className="blog-details">
                  <AppLoader />
                </div>
              ) : (
                <div className="row">
                  {currentPosts.map((post) => {
                    return (
                      <div key={post.id} className="col-xl-4 col-lg-6 col-md-6 mb-25">
                        <BlogItem
                          key={post.id}
                          title={post.title.rendered}
                          date={post.date}
                          content={post.excerpt.rendered}
                          featured_media={
                            post._embedded["wp:featuredmedia"]["0"][
                              "source_url"
                            ]
                          }
                          slug={post.slug}
                        />
                      </div>
                    );
                  })}

                  <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts={recentPosts.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BlogList;
