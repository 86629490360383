import Header from "../Header";
import Footer from "../Footer";
import BreadCrums from "../BreadCrums";
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MetaTag from "../MetaTag";
import { technicalslider, settings } from "../CarosuelsSettings";
import HTMLFlipBook from "react-pageflip";
import TabsComponentAiMl from "./TabsComponentAiMl";
import TabContentComponentAiMl from "./TabContentComponentAiMl";

const AiMl = () => {
  const MetaTitle = "AI & ML development & consulting services company | Hilton";
  const MetaDescription =
    "Hilton provides Artificial Intelligence and Machine Learning services. Explore our AI and ML services, from predictive analytics to intelligent automation.";
  const MetaKeywords =
    "AI and ML, Chatbot Development, Image Retrieval, Artificial Intelligence Solutions, Machine Learning Applications, Natural Language Processing (NLP), Customer Support Automation";
  const path = window.location.pathname;

  const [activeKey, setActiveKey] = useState('tab1');
  return (
    <>
      <MetaTag
        title={MetaTitle}
        description={MetaDescription}
        keywords={MetaKeywords}
        path={path}
      />
      <Header />
      <BreadCrums
        name={"ai1"}
        tittle={"AI & ML"}
        watermark={"AI & ML"}
      />
      <div id="" className="fulfill-ecommerce pt-60 pb-30 md-pt-60 md-pb-30 xs-pt-30 xs-pb-30">
        <div className="container-fluid custom">
          <div className="sec-title3 text-center mb-30 md-mb-30">
            <h1 className="title title2 pb-20">
            Unleashing the Power of Artificial Intelligence
            </h1>
            <span className="border-line">
                <i className="fa fa-diamond" id="diamond"></i>
            </span>
            {/* <div class="bar-main">
              <div class="bar bar-big"></div>
            </div> */}
          </div>

          <div className="row y-middle text-white">
          <div className="offset-lg-1 col-lg-10 col-md-12">
          <p className="ft16" style={{ textAlign: "center" }}>
            Artificial Intelligence (AI) is reshaping the software development landscape by introducing smart, adaptive features into your applications. Our AI services leverage cutting-edge technologies like machine learning and neural networks to enhance functionality and user experience. We design solutions that not only automate routine tasks but also learn and evolve, providing predictive insights and personalized interactions.
            </p>
            <p className="ft16" style={{ textAlign: "center" }}>Our AI-driven systems improve decision-making through data analysis and trend forecasting, ensuring your software is equipped with the latest advancements. By integrating natural language processing and advanced algorithms, we enhance user engagement and streamline processes. Stay competitive with innovative AI solutions that boost efficiency, optimize operations, and deliver significant value to your business. Harness the power of AI to drive progress and achieve your software development goals.</p>
          </div>
          </div>
        </div>
      </div>

      <div
        id="operational-ecommerce"
        className="rs-single-shop vertical operational-ecommerce  pt-20 pb-60 md-pt-60 sm-pb-68-0 hidden"
      >
        <div className="container">
          <div className="sec-title text-center mb-55 md-mb-30">
            <h2 className="title title2">
             Artificial Intelligence services
            </h2>

            <p className="margin-0">
              We offer peerless services to drive your business growth
            </p>
          </div>

          <div className="tab-area desktop">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="UI-UX-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#UI-UX"
                  type="button"
                  role="tab"
                  aria-controls="UI-UX"
                  aria-selected="true"
                >
                  <img src="/assets/images/icons/icon52.webp" /> UI/UX design
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Custom-Webapp-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Custom-Webapp"
                  type="button"
                  role="tab"
                  aria-controls="Custom-Webapp"
                  aria-selected="true"
                >
                  <img src="/assets/images/icons/icon53.webp" /> Custom web
                  application development
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Rest-API-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Rest-API"
                  type="button"
                  role="tab"
                  aria-controls="Rest-API"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/icon54.webp" /> Rest API
                  development
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Opensource-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Opensource"
                  type="button"
                  role="tab"
                  aria-controls="Opensource"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/icon56.webp" />
                  opensource framework
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Progressive-WebAps-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Progressive-WebAps"
                  type="button"
                  role="tab"
                  aria-controls="Progressive-WebAps"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/icon57.webp" /> Progressive web
                  apps
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Web-Portal-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Web-Portal"
                  type="button"
                  role="tab"
                  aria-controls="Web-Portal"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/icon58.webp" />
                  Web portal development
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Enterprise-Web-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Enterprise-Web"
                  type="button"
                  role="tab"
                  aria-controls="Enterprise-Web"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/icon59.webp" /> Enterprise web
                  development
                </button>
              </li>
            </ul>

            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="UI-UX"
                role="tabpanel"
                aria-labelledby="UI-UX-tab"
              >
                <h3>UI/UX design</h3>
                <p className="">
                  Hilton offers comprehensive solutions, including DevOps, web
                  development, consulting, and specialized UI/UX design
                  services. With our expertise in user experience engineering,
                  we deliver exceptional solutions that enhance your app's
                  uniqueness and appeal. Our team excels in crafting innovative,
                  tailored UI/UX designs, creating outstanding user experiences
                  that set your app apart.
                </p>

                <p>UI/UX solutions we offer include:</p>

                <div className="row">
                  <div className="col-md-6">
                    <ul className="disc-list">
                      <li>Front-end development</li>
                      <li>Interface design</li>
                      <li>Experience design</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="disc-list">
                      <li>Custom web design</li>
                      <li>Mobile UI/UX</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade show"
                id="Custom-Webapp"
                role="tabpanel"
                aria-labelledby="Custom-Webapp-tab"
              >
                <h3>Custom web application development</h3>

                <p className="">
                  Our focus is on creating custom applications that help
                  companies, achieve faster growth, and deliver superior
                  service. Our solutions are designed to provide unique
                  advantages for businesses by incorporating automation,
                  business process management, and integrated systems. We
                  leverage a variety of tools and technologies to achieve these
                  goals, including:
                </p>

                <div className="row">
                  <div className="col-md-6">
                    <ul className="disc-list">
                      <li>PHP/.NET/C#/Java/NodeJs</li>
                      <li>Symphony, Laravel, CakePHP</li>
                      <li>
                        MySQL/MariaDB/MongoDB/MS-SQL/PostgreSQL/Oracle/Sybase
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="Rest-API"
                role="tabpanel"
                aria-labelledby="Rest-API-tab"
              >
                <h3>Rest API development</h3>

                <p className="">
                  In the modern interconnected landscape, seamless communication
                  between applications is vital, eliminating redundancy,
                  minimizing rework, and reducing manual system interchange. Our
                  company excels in constructing resilient, secure, and scalable
                  Application Programming Interfaces (APIs) that drive the realm
                  of Service-Oriented Architecture (SOA). With our solutions,
                  businesses can leverage the extensive possibilities presented
                  by connected applications, empowering them to maintain a
                  competitive edge.
                </p>
              </div>

              <div
                className="tab-pane fade"
                id="Opensource"
                role="tabpanel"
                aria-labelledby="Opensource-tab"
              >
                <h3>Opensource framework</h3>

                <p className="">
                  Our clients receive exceptional value for their investment in
                  open-source development services. Our team follows a
                  time-bound and cost-effective development philosophy, turning
                  web ideas into reality for your online business website. By
                  harnessing cutting-edge open-source web development
                  technologies like WordPress, Joomla, Drupal, Magento, and
                  more, we deliver optimized web solutions tailored to our
                  clients' specific requirements.
                </p>

                <div className="row">
                  <div className="col-md-6">
                    <ul className="disc-list">
                      <li>CMS development services</li>
                      <li>Magento e-Commerce</li>
                      <li>Custom module development</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="disc-list">
                      <li>Web store development</li>
                      <li>Website support & maintenance</li>
                      <li>Migrations & upgradations</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="Progressive-WebAps"
                role="tabpanel"
                aria-labelledby="Progressive-WebAps-tab"
              >
                <h3>Progressive web aps</h3>
                  <p className="">
                  Progressive web applications (PWAs) leverage the strengths of
                  both web and native apps, offering an app-like experience
                  while being compatible with older browser versions. They
                  operate on desktop and mobile platforms such as Android, iOS,
                  and Windows, developed using frameworks like Angular or React.
                  PWAs utilize service workers to tap into device native
                  capabilities, granting web apps similar powers to native apps
                  within operating systems and browsers.
                </p>

                <p>
                  Basic principles to progressive web application development:
                </p>

                <div className="row">
                  <div className="col-md-6">
                    <ul className="disc-list">
                      <li>Short and simple registration</li>
                      <li>Improved design</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="disc-list">
                      <li>Focusing on specific features</li>
                      <li>Collecting custom feedback</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="Web-Portal"
                role="tabpanel"
                aria-labelledby="Web-Portal-tab"
              >
                <h3>Web portal development</h3>

                <p className="">
                  Web portal development is a meticulous process aimed at
                  constructing a secure and personalized gateway to efficiently
                  organize information according to users' specific
                  requirements. Also referred to as portal application
                  development, it necessitates expert knowledge in web
                  development, encompassing robust application development,
                  registration systems, and proficient server-side and
                  client-side scripting techniques.
                </p>
              </div>

              <div
                className="tab-pane fade"
                id="Enterprise-Web"
                role="tabpanel"
                aria-labelledby="Enterprise-Web-tab"
              >
                <h3>Enterprise web development</h3>

                <p className="">
                  At Hilton, we excel in seamlessly integrating internal
                  technical requirements, corporate branding, and ongoing
                  maintenance to deliver resilient enterprise solutions. Our
                  comprehensive services encompass the development of expansive
                  web applications, seamless integration of third-party
                  services, and establishment of microservices infrastructure,
                  all while prioritizing optimal observability capabilities and
                  on-demand scalability.
                </p>
              </div>
            </div>

            <div className="clearboth"></div>
          </div>
          {/* <!-- .tab-area --> */}
          <div className="rs-faq mobile">
            <div className="faq-content">
              <div id="accordion" className="accordion">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/icon52.webp" />
                          <span>UI/UX design</span>
                        </a>
                      </div>
                      <div
                        id="collapseOne"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>UI/UX design</h3>
                          <p className="">
                            Hilton offers comprehensive solutions, including
                            DevOps, web development, consulting, and specialized
                            UI/UX design services. With our expertise in user
                            experience engineering, we deliver exceptional
                            solutions that enhance your app's uniqueness and
                            appeal. Our team excels in crafting innovative,
                            tailored UI/UX designs, creating outstanding user
                            experiences that set your app apart.
                          </p>
                          <p>UI/UX solutions we offer include:</p>
                          <div className="row">
                            <div className="col-md-6">
                              <ul className="disc-list">
                                <li>Front-end development</li>
                                <li>Interface design</li>
                                <li>Experience design</li>
                              </ul>
                            </div>
                            <div className="col-md-6">
                              <ul className="disc-list">
                                <li>Custom web design</li>
                                <li>Mobile UI/UX</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/icon53.webp" />
                          <span>Custom web application development</span>
                        </a>
                      </div>
                      <div
                        id="collapseTwo"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Custom web application development</h3>
                          <p className="">
                            Our focus is on creating custom applications that
                            help companies, achieve faster growth, and deliver
                            superior service. Our solutions are designed to
                            provide unique advantages for businesses by
                            incorporating automation, business process
                            management, and integrated systems. We leverage a
                            variety of tools and technologies to achieve these
                            goals, including:
                          </p>
                          <div className="row">
                            <div className="col-md-6">
                              <ul className="disc-list">
                                <li>PHP/.NET/C#/Java/NodeJs</li>
                                <li>Symphony, Laravel, CakePHP</li>
                                <li>
                                  MySQL/MariaDB/MongoDB/MS-SQL/PostgreSQL/Oracle/Sybase
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/icon54.webp" />
                          <span>Rest API development</span>
                        </a>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Rest API development</h3>
                          <p className="">
                            In the modern interconnected landscape, seamless
                            communication between applications is vital,
                            eliminating redundancy, minimizing rework, and
                            reducing manual system interchange. Our company
                            excels in constructing resilient, secure, and
                            scalable Application Programming Interfaces (APIs)
                            that drive the realm of Service-Oriented
                            Architecture (SOA). With our solutions, businesses
                            can leverage the extensive possibilities presented
                            by connected applications, empowering them to
                            maintain a competitive edge.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/icon56.webp" />
                          <span>opensource framework</span>
                        </a>
                      </div>
                      <div
                        id="collapseFour"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Opensource framework</h3>
                          <p className="">
                            Our clients receive exceptional value for their
                            investment in open-source development services. Our
                            team follows a time-bound and cost-effective
                            development philosophy, turning web ideas into
                            reality for your online business website. By
                            harnessing cutting-edge open-source web development
                            technologies like WordPress, Joomla, Drupal,
                            Magento, and more, we deliver optimized web
                            solutions tailored to our clients' specific
                            requirements.
                          </p>

                          <div className="row">
                            <div className="col-md-6">
                              <ul className="disc-list">
                                <li>CMS development services</li>
                                <li>Magento e-Commerce</li>
                                <li>Custom module development</li>
                              </ul>
                            </div>
                            <div className="col-md-6">
                              <ul className="disc-list">
                                <li>Web store development</li>
                                <li>Website support & maintenance</li>
                                <li>Migrations & upgradations</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/icon57.webp" />
                          <span> Progressive web apps</span>
                        </a>
                      </div>
                      <div
                        id="collapseFive"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Progressive web aps</h3>
                          <p className="">
                            Progressive web applications (PWAs) leverage the
                            strengths of both web and native apps, offering an
                            app-like experience while being compatible with
                            older browser versions. They operate on desktop and
                            mobile platforms such as Android, iOS, and Windows,
                            developed using frameworks like Angular or React.
                            PWAs utilize service workers to tap into device
                            native capabilities, granting web apps similar
                            powers to native apps within operating systems and
                            browsers.
                          </p>
                          <p>
                            Basic principles to progressive web application
                            development:
                          </p>
                          <div className="row">
                            <div className="col-md-6">
                              <ul className="disc-list">
                                <li>Short and simple registration</li>
                                <li>Improved design</li>
                              </ul>
                            </div>
                            <div className="col-md-6">
                              <ul className="disc-list">
                                <li>Focusing on specific features</li>
                                <li>Collecting custom feedback</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/icon58.webp" />
                          <span>Web portal development</span>
                        </a>
                      </div>
                      <div
                        id="collapseSix"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Web portal development</h3>
                          <p className="">
                            Web portal development is a meticulous process aimed
                            at constructing a secure and personalized gateway to
                            efficiently organize information according to users'
                            specific requirements. Also referred to as portal
                            application development, it necessitates expert
                            knowledge in web development, encompassing robust
                            application development, registration systems, and
                            proficient server-side and client-side scripting
                            techniques.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/icon59.webp" />
                          <span>Enterprise web development</span>
                        </a>
                      </div>
                      <div
                        id="collapseSeven"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Enterprise web development</h3>
                          <p className="">
                            At Hilton, we excel in seamlessly integrating
                            internal technical requirements, corporate branding,
                            and ongoing maintenance to deliver resilient
                            enterprise solutions. Our comprehensive services
                            encompass the development of expansive web
                            applications, seamless integration of third-party
                            services, and establishment of microservices
                            infrastructure, all while prioritizing optimal
                            observability capabilities and on-demand
                            scalability.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="major-platforms"
        className="major-platforms flipk pt-20 md-pt-20"
      >
        <div className="container">
          <div className="sec-title3 text-center mb-55 md-mb-30">
            <h2 className="title title2 pb-20">
            Artificial Intelligence Services
            </h2>
            <span className="border-line">
                <i className="fa fa-diamond" id="diamond"></i>
            </span>
            <p className="margin-0 text-white">
              Come and choose your specific business requirements from our pool
              of expert solutions.
            </p>
          </div>
        </div>
      </div>
      <div
        id="ecom-platforms"
        className="ecom-platforms flipk"
      >
          <div className="container-fluid mt-4">
            <TabsComponentAiMl activeKey={activeKey} onSelect={setActiveKey} />
          </div>
          <div>
          <TabContentComponentAiMl activeKey={activeKey} />
          </div>
      </div>
      <div
        id="process-steps"
        className="mob-dev style4 pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30"
      >
        <div className="container-fluid">
          <div className="sec-title3 text-center text-white mb-60">
            <h2 className="title title2 pb-20">
            Artificial Intelligence Process
            </h2>
            <span className="border-line">
                <i className="fa fa-diamond" id="diamond"></i>
            </span>
            {/* <div class="bar-main">
                  <div class="bar bar-big"></div>
             </div> */}
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="process-steps">
                <div className="process-item bottom">
                    <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/design-thinking.webp" className="attachment-full dance_hover" alt="Define Objectives" title="Define Objectives" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Define Objectives</h3>
                      <p>Start by identifying the specific problem or opportunity that AI can address. And set clear, measurable goals for what you aim to achieve with AI technologies.</p>
                    </div>
               </div>
               <div className="process-item top">
                    <div className="icon-wrapper reverse">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/development.webp" className="attachment-full dance_hover" alt="Data Acquisition" title="Data Acquisition" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Data Acquisition</h3>
                      <p>Gather the relevant data needed to train your AI/ML models. This can include structured data (like databases) and unstructured data (like images or text).</p>
                    </div>
               </div>
               <div className="process-item bottom">
                   <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/qa.webp" className="attachment-full dance_hover" alt="Data Analysis" title="Data Analysis" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Data Analysis</h3>
                      <p>Conduct an initial analysis to uncover patterns, trends, and insights within the data.</p>
                    </div>
               </div>
               <div className="process-item top">
                    <div className="icon-wrapper reverse">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/startup.webp" className="attachment-full dance_hover" alt="Model Selection and Training" title="Model Selection and Training" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Model Selection and Training</h3>
                      <p>Choose the most suitable AI algorithms based on the nature of the problem. And train the model using the prepared data, optimizing for accuracy and efficiency.</p>
                    </div>
               </div>
               <div className="process-item">
                   <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/qa.webp" className="attachment-full dance_hover" alt="Deployment and Integration" title="Deployment and Integration" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Deployment and Integration</h3>
                      <p>Deploy the trained model into a production environment where it can make real-time predictions or decisions.</p>
                    </div>
               </div>
               </div>
            </div>
          </div>
        </div>
      </div>

      
      <div
        id="rs-services"
        className="rs-services web-dev style4 pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30 hidden"
      >
        <div className="container">
          <div className="sec-title text-center mb-60">
            <h2 className="title title2 pb-20">
              What sets us apart as web application development services
              company?
            </h2>

            <p className="dese margin-0">
              As a web app development company, we are at the forefront of
              revolutionizing web app design to deliver an unparalleled user
              experience. Our team of highly skilled developers brings extensive
              expertise in creating secure and scalable applications that cater
              to the unique needs of clients across a wide range of industries.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 md-mb-30">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="/assets/images/icons/Experienced-Developers.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-content">
                  <h3 className="title">Experienced developers</h3>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 md-mb-30">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="/assets/images/icons/Timely-Delivery.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-content">
                  <h3 className="title">Timely delivery</h3>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 md-mb-30">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="/assets/images/icons/Technical-Support.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-content">
                  <h3 className="title">Technical support</h3>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 md-mb-30">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="/assets/images/icons/Flexible-Engagement.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-content">
                  <h3 className="title">Flexible engagement models</h3>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 md-mb-30">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="/assets/images/icons/Transparency.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-content">
                  <h3 className="title">100% Transparency</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Full-stack-portfolio" className="pt-60 pb-60 md-pt-60 sm-pb-60 xs-pt-30 xs-pb-30 bg-black">
          <div className="container">
          <div className="sec-title3 text-center mb30 md-mb-30">
            <h2 className="title title2 pb-20">AI & ML Portfolio</h2>
            <span className="border-line">
                <i className="fa fa-diamond" id="diamond"></i>
            </span>
              {/* <div class="bar-main">
                  <div class="bar bar-big"></div>
              </div> */}
          </div>
              <div className="book-wrapper">
              <HTMLFlipBook
                width={500}
                height={600}
                size="stretch"
                startPage={1}  // Start with the first page open
                showCover={false}
                maxShadowOpacity={0.5}
                className="book-container"
              >
                <div className="page1">
                <div className="service-wrap">
                          <div className="client-logo">
                            <h2>HANDYMAN</h2>
                            <h3>HANDYMAN</h3>
                            {/* <img
                              src="/assets/images/brands/hanyman.webp"
                              alt="images"
                            /> */}
                          </div>
                          <div className="client-banner">
                            <img
                              src="/assets/images/portfolio/handyman.webp"
                              alt="images"
                            />
                          </div>
            </div>
                </div>
                {/* <div className="page1">
                  <div className="about-img">
                      <img
                        src="/assets/images/portfolio/handyman.webp"
                        alt="images"
                      />
                  </div>
                  <div className="page-flip-marker">➔</div>
                </div> */}
                <div className="page1">
                  <div className="service-wrap">
                       <div className="client-logo">
                            <h2>Partlinking</h2>
                            <img
                              src="/assets/images/brands/partlinking.webp"
                              alt="images"
                            />
                          </div>
                          <div className="client-banner">
                            <img
                              src="/assets/images/portfolio/partlinking-screen.webp"
                              alt="images"
                            />
                          </div>
                  </div>
                </div>
              </HTMLFlipBook>
        </div>
         </div>
    </div>
      <Footer />
    </>
  );
};

export default AiMl;
