import axios from "axios";
import {
  SENDGRID_API_KEY,
  SENDGRID_API_URL,
  sendermail,
  receivermail,
} from "../../Api";

export const sendContactEmail = async (formData) => {
  const subject = `New Contact Form Submission from ${formData.name}`;
  const emailContent = `
    Name: ${formData.name}
    Email: ${formData.email}
    Phone: ${formData.phone}
    Website: ${formData.subject}
    Service: ${formData.services}
    Message: ${formData.message}
  `;

  const data = {
    personalizations: [
      {
        // to: [{ email: "sunil.gonugunta@hiltonglobal.in", name: "Hilton" }],
        to: [{ email: receivermail, name: "Hilton" }],
        subject: subject, // Change the subject as needed
      },
    ],
    content: [{ type: "text/plain", value: emailContent }],
    from: { email: sendermail, name: "Hilton" },
    reply_to: { email: formData.email, name: formData.name },
  };
  try {
    const { type, message } = await SendMail(data);
    if (type === "success") {
      const successMessage =
        "We appreciate your interest in our products/services." +
        "Our team will review your message and get back to you within 24 hours.";
      return { type: "success", message: successMessage };
    }

    return { type, message };
  } catch (error) {
    console.log("error",error)
    return { type: "error", message: "Failed to send email." };
  }
};

export const sendJobRequestEmail = async (formData) => {
  const subject = `New Enquiry from ${formData.name}`;

  const emailContent = `
    Name: ${formData.name}
    Email: ${formData.email}
    Phone: ${formData.phone}
    Position: ${formData.position}
    Message: ${formData.message}
  `;

  const base64Content = await getFileBase64Content(formData.file);

  const data = {
    personalizations: [
      {
        // to: [{ email: "sunil.gonugunta@hiltonglobal.in", name: "Hilton" }],
        to: [{ email: "info@hiltonglobal.in", name: "Hilton" }],
        subject: subject,
      },
    ],
    content: [{ type: "text/plain", value: emailContent }],
    from: { email: "info@hiltonglobal.in", name: "Hilton" },
    reply_to: { email: formData.email, name: formData.name },
    attachments: [
      {
        content: base64Content,
        type: "text/plain",
        filename: formData.file.name,
      },
    ],
  };

  try {
    const { type, message } = await SendMail(data);
    if (type === "success") {
      const successMessage =
        "Thank you for submitting, " +
        "we appreciate your interest in joining our team. " +
        "Our team will review, and get back to you as soon as possible.";
      return { type: "success", message: successMessage };
    }

    return { type, message };
  } catch (error) {
    return { type: "error", message: "Failed to send email." };
  }
};

const getFileBase64Content = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Content = reader.result.split(",")[1];
      resolve(base64Content);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

const SendMail = async (data) => {
  try {
    const response = await axios.post(SENDGRID_API_URL, data, {
      headers: {
        Authorization: `Bearer ${SENDGRID_API_KEY}`,
        "Content-Type": "application/json",
      },
    });
    const successMessage = "Email sent successfully!";
    return { type: "success", message: successMessage };
  } catch (error) {
    const errorMessage = "Failed to send email.";
    return { type: "error", message: errorMessage };
  }
};
