import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import BreadCrums from "../BreadCrums";
import { settings } from "../CarosuelsSettings";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MetaTag from "../MetaTag";
import { Tab, Tabs } from "react-bootstrap";
import TabsComponent from "./TabsComponent";
import TabContentComponent from "./TabContentComponent";
import HTMLFlipBook from "react-pageflip";
import Background from "../Background";

const EcommerceDevelopment = () => {
  const MetaTitle = "E commerce Website Development services company | Hilton";
  const MetaDescription =
    "Ecommerce development company develop superior custom eCommerce websites to grow your online business and sales globally.";
  const MetaKeywords =
    "E-commerce Website Development Company, e-commerce website designing";
  const path = window.location.pathname;
  const [activeKey, setActiveKey] = useState('tab1');
  return (
    <>
      <MetaTag
        title={MetaTitle}
        description={MetaDescription}
        keywords={MetaKeywords}
        path={path}
      />
      <Header />
      <BreadCrums
        name={"e-commerce"}
        tittle={"e-Commerce development"}
        watermark={"e-Commerce development"}
      />
      {/* <Background /> */}
      {/*
      <!-- fulfill-ecommerce Section Start --> */}

      <div id="" className="fulfill-ecommerce pt-60 pb-30 md-pt-60 md-pb-30 xs-pt-30 xs-pb-30">
        <div className="container-fluid custom">
          <div className="sec-title3 text-center">
            <h1 className="title title2 pb-20">
            Comprehensive e-Commerce Development Solutions
            </h1>
                <span className="border-line">
                  <i className="fa fa-diamond" id="diamond"></i>
                  </span>
            {/* <div class="bar-main">
                  <div class="bar bar-big"></div>
             </div> */}
             </div>
             <div className="row y-middle text-white">
          <div className="offset-lg-1 col-lg-10 col-md-12">
          <p className="ft16" style={{ textAlign: "center" }}>
            Our e-commerce development services are designed to propel your business to new heights. We provide a robust, secure and customizable platform that enhances every aspect of your online store. Partnering with us opens up limitless opportunities for developing, innovating and growing your business. Whether you’re revamping your current system or launching a new online presence, we deliver solutions that support and expand your business. Discover limitless opportunities for innovation and advancement with our dedicated services, and position your business at the forefront of the digital marketplace.
            </p>
            </div>
          </div>
        </div>
      </div>

      {/*
      <!-- operational-ecommerce Section Start --> */}

      <div
        id="operational-ecommerce"
        className="rs-single-shop vertical operational-ecommerce  pt-60 pb-60 md-pt-60 sm-pb-60 hidden"
      >
        <div className="container">
          <div className="sec-title text-center mb-55 md-mb-30">
            <h2 className="title title2">Operational e-Commerce solutions</h2>

            <p className="margin-0">
              We provide unparalleled services to accelerate the growth of your
              business.
            </p>
          </div>

          <div className="tab-area desktop">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="Logistic-Management-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Logistic-Management"
                  type="button"
                  role="tab"
                  aria-controls="Logistic-Management"
                  aria-selected="true"
                >
                  <img src="/assets/images/icons/icon52.webp" /> Logistic
                  Management
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Inventory-Management-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Inventory-Management"
                  type="button"
                  role="tab"
                  aria-controls="Inventory-Management"
                  aria-selected="true"
                >
                  <img src="/assets/images/icons/icon53.webp" /> Inventory
                  Management
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Warehouse-Management-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Warehouse-Management"
                  type="button"
                  role="tab"
                  aria-controls="Warehouse-Management"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/icon54.webp" /> Warehouse
                  Management
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Full-Cycle-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Full-Cycle"
                  type="button"
                  role="tab"
                  aria-controls="Full-Cycle"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/icon55.webp" /> Full Cycle
                  ERP/CRM Solution
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="PIM-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#PIM"
                  type="button"
                  role="tab"
                  aria-controls="PIM"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/icon56.webp" /> Product
                  Information Management (PIM)
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Document-Management-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Document-Management"
                  type="button"
                  role="tab"
                  aria-controls="Document-Management"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/icon57.webp" /> Document
                  Management
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="POS-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#POS"
                  type="button"
                  role="tab"
                  aria-controls="POS"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/icon58.webp" /> e-Commerce POS
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Loyalty-Programs-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Loyalty-Programs"
                  type="button"
                  role="tab"
                  aria-controls="Loyalty-Programs"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/icon59.webp" /> Loyalty
                  Programs Integration
                </button>
              </li>
            </ul>

            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="Logistic-Management"
                role="tabpanel"
                aria-labelledby="Logistic-Management-tab"
              >
                <h3>Logistic management</h3>

                <p className="">
                  e-Commerce logistics management encompasses computerized
                  systems and collaboration among consumers, retailers,
                  suppliers, and logistics companies. Specialized logistics
                  firms handle most logistics tasks, with retailers playing a
                  supporting role in the supply chain.
                </p>

                <p>At Hilton, our logistic management solution will offer:</p>

                <div className="row">
                  <div className="col-md-6">
                    <ul className="disc-list">
                      <li>Enhanced customer experience</li>
                      <li>Real-time visibility</li>
                      <li>Scalability</li>
                      <li>On-time delivery, and more</li>
                    </ul>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>

              <div
                className="tab-pane fade show"
                id="Inventory-Management"
                role="tabpanel"
                aria-labelledby="Inventory-Management-tab"
              >
                <h3>Inventory management</h3>

                <p className="">
                  Efficient inventory management is vital for e-Commerce
                  success, preventing financial losses and meeting customer
                  demands. Establish an accurate, efficient, and cost-effective
                  inventory management process using the following tips.
                </p>

                <p>
                  Our e-Commerce operational solution comes with matchless
                  features:
                </p>

                <div className="row">
                  <div className="col-md-6">
                    <ul className="disc-list">
                      <li>Inventory tracking</li>
                      <li>Inventory scanner system</li>
                      <li>Inventory pick list</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="disc-list">
                      <li>Inventory control</li>
                      <li>Inventory distribution</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade show"
                id="Warehouse-Management"
                role="tabpanel"
                aria-labelledby="Warehouse-Management-tab"
              >
                <h3>Warehouse management</h3>

                <p className="">
                  Our warehouse management system is designed to efficiently
                  oversee and streamline daily activities, including receiving,
                  storing, picking, packing, shipping, and replenishment. Our
                  system optimizes these processes to ensure maximum efficiency.
                </p>

                <p>You are going to acknowledge the following in our system:</p>

                <div className="row">
                  <div className="col-md-6">
                    <ul className="disc-list">
                      <li>Hiring and training staff</li>
                      <li>Equipment maintenance</li>
                      <li>Inventory tracking</li>
                      <li>Labor allocation</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="disc-list">
                      <li>Fulfilling orders</li>
                      <li>Ensuring order fulfillment</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade show"
                id="Full-Cycle"
                role="tabpanel"
                aria-labelledby="Inventory-Management-tab"
              >
                <h3>Full cycle ERP/CRM solution</h3>

                <p className="">
                  This e-Commerce solution enables streamlined operations,
                  enhancing efficiency and providing a competitive edge in the
                  market.
                </p>

                <p>
                  Our e-Commerce ERP/CRM solution offers powerful features,
                  including:
                </p>

                <div className="row">
                  <div className="col-md-6">
                    <ul className="disc-list">
                      <li>Enhanced lead generation</li>
                      <li>HR management</li>
                      <li>Efficient supply chain management</li>
                      <li>Effective project management</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="disc-list">
                      <li>Pricing management</li>
                      <li>Timely customer support</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade show"
                id="PIM"
                role="tabpanel"
                aria-labelledby="PIM-tab"
              >
                <h3>Product information management (PIM)</h3>

                <p className="">
                  PIM is a system that centralizes product data from multiple
                  channels, simplifying marketing and sales management. Our
                  system collects product data from various distribution
                  channels and consolidates it into a centralized location for
                  convenient access and streamlined management.
                </p>

                <div className="row">
                  <div className="col-md-610">
                    <ul className="disc-list">
                      <p>
                        <b>Our key features are:</b>
                      </p>
                      <li>
                        Manual and automatic product information collection
                      </li>
                      <li>Ability to handle multiple SKUs</li>
                      <li>Workflow automation</li>
                      <li>
                        Content and marketing material management for each
                        product
                      </li>
                      <li>
                        Customizable workflows for different departments and
                        parts of the supply chain
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade show"
                id="Document-Management"
                role="tabpanel"
                aria-labelledby="Warehouse-Management-tab"
              >
                <h3>Document management</h3>

                <p className="">
                  Our e-Commerce solution incorporates a robust document
                  management system, enabling effortless file and folder
                  management, including copying, deleting, moving, uploading,
                  extracting, archiving, and renaming files.
                </p>

                <p>
                  An ideal document management solution should possess the
                  following traits:
                </p>

                <div className="row">
                  <div className="col-md-10">
                    <ul className="disc-list">
                      <li>Reduced storage space requirements</li>
                      <li>Accelerated business processes</li>
                      <li>Improved employee efficiency</li>
                      <li>Up to 60% reduction in operational costs</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade show"
                id="POS"
                role="tabpanel"
                aria-labelledby="Warehouse-Management-tab"
              >
                <h3>e-Commerce POS</h3>

                <p className="">
                  Point of sale (POS) in various industries involves hardware
                  and software such as scanners, registers, touch screen
                  terminals, and weighing scales. It is also referred to as a
                  point of service as it's used for both sales and returns.
                </p>

                <p>Our POS solution encompasses the following key features:</p>

                <div className="row">
                  <div className="col-md-6">
                    <ul className="disc-list">
                      <li>Automated inventory syncing</li>
                      <li>Centralized control of all orders</li>
                      <li>Integrated shipping capabilities</li>
                      <li>Seamless integration with email marketing</li>
                      <li>Order status text alerts </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade show"
                id="Loyalty-Programs"
                role="tabpanel"
                aria-labelledby="Warehouse-Management-tab"
              >
                <h3> Loyalty programs integration</h3>

                <p className="">
                  By seamlessly incorporating loyalty programs into your
                  business, you can effectively manage operations while
                  fostering customer loyalty through rewards for frequent
                  product and service usage.
                </p>

                <p>
                  A well-rounded loyalty programs integration should encompass
                  the following key components:
                </p>

                <div className="row">
                  <div className="col-md-6">
                    <ul className="disc-list">
                      <li>Bonus cards </li>
                      <li>Cashback incentives</li>
                      <li>Promo codes</li>
                      <li>Reward point system </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*
              <!-- .tab-area --> */}
          <div className="rs-faq mobile">
            <div className="faq-content">
              <div id="accordion" className="accordion">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/icon52.webp" />{" "}
                          Logistic Management
                        </a>
                      </div>
                      <div
                        id="collapseOne"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Logistic management</h3>
                          <p className="">
                            e-Commerce logistics management encompasses
                            computerized systems and collaboration among
                            consumers, retailers, suppliers, and logistics
                            companies. Specialized logistics firms handle most
                            logistics tasks, with retailers playing a supporting
                            role in the supply chain.
                          </p>
                          <p>
                            At Hilton, our logistic management solution will
                            offer:
                          </p>
                          <div className="row">
                            <div className="col-md-6">
                              <ul className="disc-list">
                                <li>Enhanced customer experience</li>
                                <li>Real-time visibility</li>
                                <li>Scalability</li>
                                <li>On-time delivery, and more</li>
                              </ul>
                            </div>
                            <div className="col-md-6"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/icon53.webp" />{" "}
                          Inventory Management
                        </a>
                      </div>
                      <div
                        id="collapseTwo"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Inventory management</h3>
                          <p className="">
                            Efficient inventory management is vital for
                            e-Commerce success, preventing financial losses and
                            meeting customer demands. Establish an accurate,
                            efficient, and cost-effective inventory management
                            process using the following tips.
                          </p>
                          <p>
                            Our e-Commerce operational solution comes with
                            matchless features:
                          </p>
                          <div className="row">
                            <div className="col-md-6">
                              <ul className="disc-list">
                                <li>Inventory tracking</li>
                                <li>Inventory scanner system</li>
                                <li>Inventory pick list</li>
                              </ul>
                            </div>
                            <div className="col-md-6">
                              <ul className="disc-list">
                                <li>Inventory control</li>
                                <li>Inventory distribution</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/icon54.webp" />{" "}
                          Warehouse Management
                        </a>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Warehouse management</h3>
                          <p className="">
                            Our warehouse management system is designed to
                            efficiently oversee and streamline daily activities,
                            including receiving, storing, picking, packing,
                            shipping, and replenishment. Our system optimizes
                            these processes to ensure maximum efficiency.
                          </p>
                          <p>In our system, you will find:</p>
                          <div className="row">
                            <div className="col-md-6">
                              <ul className="disc-list">
                                <li>Hiring and training staff</li>
                                <li>Maintaining equipment</li>
                                <li>Inventory tracking</li>
                                <li>Labor allocation</li>
                              </ul>
                            </div>
                            <div className="col-md-6">
                              <ul className="disc-list">
                                <li>Fulfilling orders</li>
                                <li>Ensuring order fulfillment</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/icon55.webp" /> Full
                          Cycle ERP/CRM Solution
                        </a>
                      </div>
                      <div
                        id="collapseFour"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Full cycle ERP/CRM solution</h3>

                          <p className="">
                            This e-Commerce solution enables streamlined
                            operations, enhancing efficiency and providing a
                            competitive edge in the market.
                          </p>

                          <p>
                            Our e-Commerce ERP/CRM solution offers powerful
                            features, including:
                          </p>

                          <div className="row">
                            <div className="col-md-6">
                              <ul className="disc-list">
                                <li>Enhanced lead generation</li>
                                <li>HR management</li>
                                <li>Efficient supply chain management</li>
                                <li>Effective project management</li>
                              </ul>
                            </div>
                            <div className="col-md-6">
                              <ul className="disc-list">
                                <li>Pricing management</li>
                                <li>Timely customer support</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/icon56.webp" /> Product
                          Information Management (PIM)
                        </a>
                      </div>
                      <div
                        id="collapseFive"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Product information management (PIM)</h3>
                          <p className="">
                            PIM is a system that centralizes product data from
                            multiple channels, simplifying marketing and sales
                            management. Our system collects product data from
                            various distribution channels and consolidates it
                            into a centralized location for convenient access
                            and streamlined management.
                          </p>
                          <div className="row">
                            <div className="col-md-610">
                              <ul className="disc-list">
                                <p>
                                  <b>Our key features are:</b>
                                </p>
                                <li>
                                  Manual and automatic product information
                                  collection
                                </li>
                                <li>Ability to handle multiple SKUs</li>
                                <li>Workflow automation</li>
                                <li>
                                  Content and marketing material management for
                                  each product
                                </li>
                                <li>
                                  Customizable workflows for different
                                  departments and parts of the supply chain
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/icon57.webp" />{" "}
                          Document Management
                        </a>
                      </div>
                      <div
                        id="collapseSix"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Document management</h3>
                          <p className="">
                            Our e-Commerce solution incorporates a robust
                            document management system, enabling effortless file
                            and folder management, including copying, deleting,
                            moving, uploading, extracting, archiving, and
                            renaming files.{" "}
                          </p>
                          <p>
                            An ideal document management solution should possess
                            the following traits:
                          </p>
                          <div className="row">
                            <div className="col-md-10">
                              <ul className="disc-list">
                                <li>Reduced storage space requirements</li>
                                <li>Accelerated business processes</li>
                                <li>Improve employee efficiency</li>
                                <li>Reduce operational costs by up to 60%</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/icon58.webp" />{" "}
                          e-Commerce POS
                        </a>
                      </div>
                      <div
                        id="collapseSeven"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>e-Commerce POS</h3>
                          <p className="">
                            Point of Sale (POS) systems are essential in many
                            industries, incorporating hardware and software
                            components like scanners, registers, touch screen
                            terminals, and weighing scales. It serves as a point
                            of service for sales and returns.
                          </p>
                          <p>
                            Our POS solution encompasses the following key
                            features:
                          </p>
                          <div className="row">
                            <div className="col-md-6">
                              <ul className="disc-list">
                                <li>Automatic inventory syncing</li>
                                <li>Centralized control of all orders</li>
                                <li>Integrated shipping</li>
                                <li>Email marketing integration</li>
                                <li>Order status text alerts </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEight"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/icon59.webp" /> Loyalty
                          Programs Integration
                        </a>
                      </div>
                      <div
                        id="collapseEight"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3> Loyalty programs integration</h3>
                          <p className="">
                            By seamlessly incorporating loyalty programs into
                            your business, you can effectively manage operations
                            while fostering customer loyalty through rewards for
                            frequent product and service usage.
                          </p>
                          <p>
                            A well-rounded loyalty programs integration should
                            encompass the following key components:
                          </p>
                          <div className="row">
                            <div className="col-md-6">
                              <ul className="disc-list">
                                <li>Bonus cards </li>
                                <li>Cashback</li>
                                <li>Promo codes</li>
                                <li>Reward point system </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="major-platforms"
        className="major-platforms flipk pt-50 md-pt-40"
      >
        <div className="container">
          <div className="sec-title3 text-center mb-55 md-mb-30">
            <h2 className="title title2 pb-20">
              Dominant e-Commerce platforms we expertly navigate.
            </h2>
            <span className="border-line">
                  <i className="fa fa-diamond" id="diamond"></i>
                  </span>
            <p className="margin-0 text-white">
              Come and choose your specific business requirements from our pool
              of expert solutions.
            </p>
            {/* <div class="bar-main">
                  <div class="bar bar-big"></div>
             </div> */}
          </div>

          {/* <div className="row justify-content-center">
            <a className="service-item">
              {" "}
              <img
                alt="Magento"
                height="84"
                width="102"
                src="/assets/images/technologies/Magento.webp"
              />
              <h4>Magento</h4>
            </a>

            <a className="service-item">
              {" "}
              <img
                alt="Shopify"
                height="84"
                width="102"
                src="/assets/images/technologies/Shopify-1.webp"
              />
              <h4>Shopify</h4>
            </a>

            <a className="service-item">
              {" "}
              <img
                alt="WooCommerce"
                height="84"
                width="102"
                src="/assets/images/technologies/woocommerce.webp"
              />
              <h4>woo-Commerce</h4>
            </a>

            <a className="service-item">
              {" "}
              <img
                alt="BigCommerce"
                height="84"
                width="102"
                src="/assets/images/technologies/bigcommere.webp"
              />
              <h4>big-Commerce</h4>
            </a>
          </div> */}
        </div>
      </div>
    
      <div
        id="ecom-platforms"
        className="ecom-platforms flipk"
      >
       <div className="container-fluid mt-4">
            <TabsComponent activeKey={activeKey} onSelect={setActiveKey} />
          </div>
          <div>
          <TabContentComponent activeKey={activeKey} />
          </div>
      </div>

      <div
        id="dev-process"
        className="pt-60 pb-30 md-pt-60 sm-pb-60 xs-pt-30 xs-pb-30 bg-black"
      >
        <div className="container">
          <div className="sec-title3 text-center mb-55 md-mb-30">
            <h2 className="title title2 pb-20">
              e-Commerce Development Process
            </h2>
            <span className="border-line">
                  <i className="fa fa-diamond" id="diamond"></i>
                  </span>
            {/* <div class="bar-main">
                  <div class="bar bar-big"></div>
                </div> */}
          </div>
          <div className="road-map-main">
            <div className="road-map-wrapper">
              <div className="road-map-circle">
                <span className="road-map-circle-text d-flex align-items-center justify-content-center">
                  <span className="title">Requirements Gathering</span>
                </span>
              </div>
              <div className="road-map-card">
                <h4 className="card-head">Requirements Gathering</h4>
                <p className="card-text">
                Collaborate with the client to understand their business goals, target audience, product catalog, and platform preferences.
                </p>
              </div>
            </div>
            <div className="road-map-wrapper">
              <div className="road-map-circle">
                <span className="road-map-circle-text d-flex align-items-center justify-content-center">
                Platform Selection & Architecture Design
                </span>
              </div>
              <div className="road-map-card">
                <h4 className="card-head">Platform Selection & Architecture Design</h4>
                <p className="card-text">
                  Choose the right e-commerce platform based on the client's needs. Design the system architecture, ensuring scalability, security, and integration with third-party services like ERP, CRM, or shipping providers.
                </p>
              </div>
            </div>
            <div className="road-map-wrapper">
              <div className="road-map-circle">
                <span className="road-map-circle-text d-flex align-items-center justify-content-center">
                  <span>UI/UX Design</span>
                </span>
              </div>
              <div className="road-map-card">
                <h4 className="card-head">UI/UX Design</h4>
                <p className="card-text">
                Create wireframes and prototypes focusing on intuitive navigation, a visually appealing interface, and a mobile-responsive design. User experience is crucial for conversion rates and customer retention.
                </p>
              </div>
            </div>
            <div className="road-map-wrapper">
              <div className="road-map-circle">
                <span className="road-map-circle-text d-flex align-items-center justify-content-center">
                  Front-end & Back-end
                </span>
              </div>
              <div className="road-map-card">
                <h4 className="card-head">Front-end & Back-end</h4>
                <p className="card-text">
                Build the user interface with HTML, CSS, JavaScript, and integrate the design into the chosen platform.Develop server-side components, configure databases, set up payment gateways, and ensure security measures like SSL and data encryption.
                </p>
              </div>
            </div>
            <div className="road-map-wrapper">
              <div className="road-map-circle">
                <span className="road-map-circle-text d-flex align-items-center justify-content-center">
                  <span>Testing</span>
                </span>
              </div>
              <div className="road-map-card">
                <h4 className="card-head">Testing</h4>
                <p className="card-text">
                Conduct rigorous testing to ensure the site works smoothly across different devices and browsers. Address bugs and performance issues before going live.
                </p>
              </div>
            </div>
            <div className="road-map-wrapper">
              <div className="road-map-circle">
                <span className="road-map-circle-text d-flex align-items-center justify-content-center">
                Deployment
                </span>
              </div>
              <div className="road-map-card">
                <h4 className="card-head">Deployment</h4>
                <p className="card-text">
                Launch the e-commerce platform on a live server. Ensure everything is set up for a smooth user experience, domain setup and server optimization for speed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="progressive-app"
        className="progressive-app pt-60 pb-30 md-pt-60 sm-pb-60 xs-pt-30 xs-pb-30 bg-black2"
      >
        <div className="container">
          <div className="sec-title3 text-center mb-30 md-mb-30">
            <h2 className="title title2 pb-20">
              Boost e-Commerce sales with our innovative web app development.
            </h2>
            <span className="border-line">
                  <i className="fa fa-diamond" id="diamond"></i>
                  </span>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 mb-35">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    src="/assets/images/services/style6/1.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-text">
                  <h2 className="title">Progressive web app development</h2>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mb-35">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    src="/assets/images/services/style6/2.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-text">
                  <h2 className="title">Progressive web app design</h2>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mb-35">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    src="/assets/images/services/style6/3.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-text">
                  <h2 className="title">Custom PWA development</h2>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 md-mb-35">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    src="/assets/images/services/style6/4.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-text">
                  <h2 className="title">
                    Seamless integration & secure migration
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="e-Commerce-portfolio" className="pt-60 pb-60 xs-pt-30 xs-pb-30 bg-black">
        <div className="container">
          <div className="sec-title3 text-center mb-30 md-mb-30">
            <h2 className="title title2 pb-20">e-Commerce portfolio</h2>
                <span className="border-line">
                  <i className="fa fa-diamond" id="diamond"></i>
                </span>
            {/* <div class="bar-main">
                  <div class="bar bar-big"></div>
                </div> */}
          </div>
          <div className="book-wrapper">
              <HTMLFlipBook
                width={500}
                height={600}
                size="stretch"
                startPage={1}  // Start with the first page open
                showCover={false}
                maxShadowOpacity={0.5}
                className="book-container"
              >
                <div className="page1">
                  <div className="service-wrap">
                          <div className="client-logo">
                              <h2>Cotstyle</h2>
                              <img
                                src="/assets/images/brands/cotstyle.webp"
                                alt="images"
                              />
                            </div>
                            <div className="client-banner">
                              <img
                                src="/assets/images/portfolio/cotstyle.webp"
                                alt="images"
                              />
                            </div>
                  </div>
                 </div>
                <div className="page1">
                <div className="service-wrap">
                           <div className="client-logo">
                            <h2>Brandmandir</h2>
                            <img
                              src="/assets/images/brands/mandir.webp"
                              alt="images"
                            />
                          </div>
                          <div className="client-banner">
                            <img
                              src="/assets/images/portfolio/brandmandir.webp"
                              alt="images"
                            />
                          </div>
                  </div>
                  <div className="page-flip-marker">→</div>
                </div>
                {/* <div className="page1">
                        <div className="about-img">
                            <img
                              src="/assets/images/portfolio/brandmandir.webp"
                              alt="images"
                            />
                        </div>
                  <div className="page-flip-marker">➔</div>
                </div> */}
                <div className="page1">
                <div className="service-wrap">
                          <div className="client-logo">
                            <h2>Ignite Candle bar</h2>
                            <img
                              src="/assets/images/brands/ignite.webp"
                              alt="images"
                            />
                          </div>
                          <div className="client-banner">
                            <img
                              src="/assets/images/portfolio/ignite.webp"
                              alt="images"
                            />
                          </div>
                  </div>
                  <div className="page-flip-marker">←</div>
                </div>
                {/* <div className="page1">
                        <div className="about-img">
                            <img
                              src="/assets/images/portfolio/ignite.png"
                              alt="images"
                            />
                        </div>
                  <div className="page-flip-marker">➔</div>
                </div> */}
                <div className="page1">
                <div className="service-wrap">
                          <div className="client-logo">
                            <h2>Lujobox</h2>
                            <img
                              src="/assets/images/brands/luzobox.webp"
                              alt="images"
                            />
                          </div>
                          <div className="client-banner">
                            <img
                              src="/assets/images/portfolio/lujobox.webp"
                              alt="images"
                            />
                          </div>
                 </div>
                 <div className="page-flip-marker">→</div>
                </div>
                {/* <div className="page1">
                  <div className="about-img">
                      <img
                        src="/assets/images/portfolio/lujobox1.webp"
                        alt="images"
                      />
                  </div>
                  <div className="page-flip-marker">➔</div>
                </div> */}
                <div className="page1">
                <div className="service-wrap">
                          <div className="client-logo">
                            <h2>Kalamandir</h2>
                            <img
                              src="/assets/images/brands/kalamandir.webp"
                              alt="images"
                            />
                          </div>
                          <div className="client-banner">
                            <img
                              src="/assets/images/portfolio/kalamandir.webp"
                              alt="images"
                            />
                          </div>
                  </div>
                  <div className="page-flip-marker">←</div>
                </div>
                {/* <div className="page1">
                <div className="about-img">
                      <img
                        src="/assets/images/portfolio/kalamandir.webp"
                        alt="images"
                      />
                  </div>
                 <div className="page-flip-marker">➔</div>
                </div> */}
                <div className="page1">
                <div className="service-wrap">
                          <div className="client-logo">
                            <h2>Kanchivml</h2>
                            <img
                              src="/assets/images/brands/KVM.webp"
                              alt="images"
                            />
                          </div>
                          <div className="client-banner">
                            <img
                              src="/assets/images/portfolio/kanchivml.webp"
                              alt="images"
                            />
                          </div>
            </div>
            <div className="page-flip-marker">→</div>
                </div>
                {/* <div className="page1">
                <div className="about-img">
                      <img
                        src="/assets/images/portfolio/kanchivml.webp"
                        alt="images"
                      />
                  </div>
                 <div className="page-flip-marker">➔</div>
                </div> */}
                <div className="page1">
                <div className="service-wrap">
                          <div className="client-logo">
                            <h2>Neerus</h2>
                            <img
                              src="/assets/images/brands/neerus.webp"
                              alt="images"
                            />
                          </div>
                          <div className="client-banner">
                            <img
                              src="/assets/images/portfolio/neerus.webp"
                              alt="images"
                            />
                          </div>
            </div>
            <div className="page-flip-marker">←</div>
                </div>
                <div className="page1">
                  <div className="service-wrap">
                         <div className="client-logo">
                            <h2>Bachlr</h2>
                            <img
                              src="/assets/images/brands/bachlr.webp"
                              alt="images"
                            />
                          </div>
                          <div className="client-banner">
                            <img
                              src="/assets/images/portfolio/bachlr.webp"
                              alt="images"
                            />
                          </div>
                    </div>
                    <div className="page-flip-marker">→</div>
                </div>
                <div className="page1">
                  <div className="service-wrap">
                         <div className="client-logo">
                            <h2>Usedstore</h2>
                            <img
                              src="/assets/images/brands/used.webp"
                              alt="images"
                            />
                          </div>
                          <div className="client-banner">
                            <img
                              src="/assets/images/portfolio/usedstore.webp"
                              alt="images"
                            />
                          </div>
                    </div>
                    <div className="page-flip-marker">←</div>
                 </div>
              </HTMLFlipBook>
        </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EcommerceDevelopment;
